@font-face {
  font-family: 'Avenir Next W1G';
  src: url('./fonts/AvenirNextW1G-Heavy.woff2') format('woff2'),
      url('./fonts/AvenirNextW1G-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next W1G Ultra';
  src: url('./fonts/AvenirNextW1G-UltraLight.woff2') format('woff2'),
      url('./fonts/AvenirNextW1G-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next W1G';
  src: url('./fonts/AvenirNextW1G-Bold.woff2') format('woff2'),
      url('./fonts/AvenirNextW1G-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next W1G';
  src: url('./fonts/AvenirNextW1G-Regular.woff2') format('woff2'),
      url('./fonts/AvenirNextW1G-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next W1G';
  src: url('./fonts/AvenirNextW1G-Demi.woff2') format('woff2'),
      url('./fonts/AvenirNextW1G-Demi.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next W1G';
  src: url('./fonts/AvenirNextW1G-Medium.woff2') format('woff2'),
      url('./fonts/AvenirNextW1G-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next W1G Light';
  src: url('./fonts/AvenirNextW1G-Light.woff2') format('woff2'),
      url('./fonts/AvenirNextW1G-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
body{
  color:#2b292b;
}
@font-face {
  font-family: "DinCondensedDemiBold";
  src: url('./fonts/DinCondensedDemiBold/font.woff2') format('woff2'), url('./fonts/DinCondensedDemiBold/font.woff') format('woff');
}





@tailwind base;
@tailwind components;
@tailwind utilities;
.font-din{
  font-family: 'DinCondensedDemiBold', sans-serif;
}
.font-avenir{
  font-family: 'Avenir Next W1G', sans-serif;
}
body{
  font-family: 'Avenir Next W1G';

}
.header-info{
  @apply bg-[#6408c4] text-white pt-[150px] lg:pt-0  lg:pl-6 lg:pr-16 h-screen z-40  lg:h-[74px] block lg:flex  items-center;
  border-radius: 0 0 0 55px
}
.header-info ul{
  @apply items-center block space-y-8 lg:space-y-0 lg:space-x-4 lg:flex;
}
.header-info ul li{

  position: relative;
  height: 100%;
}
.header-info ul li ul {
  @apply lg:hidden lg:absolute lg:left-0 lg:top-[22px] bg-[#6408c4] block w-full lg:w-[180px] space-x-0 lg:px-3 lg:space-y-3 h-auto lg:pt-[30px];
  
}
.header-info ul li ul li{
  @apply pt-8 lg:pt-0
}
.header-info ul li ul li:last-child{
  @apply pt-0 lg:pt-0;
}
.header-info ul li:hover ul{
  @apply block
}
.header-info a{
  font-family: 'DinCondensedDemiBold', sans-serif;
  @apply w-full lg:w-fit block text-center text-white text-[18px] lg:text-[14px] 2xl:text-[18px] 2xl:text-[22px] font-bold leading-[22px] 2xl:leading-[30px] pt-[2px] uppercase;

}
.header-info a.last{
  @apply block w-8/12 mx-auto lg:w-auto text-center bg-white rounded-2xl text-[#2b292b] px-5 lg:flex content-center;
}
.container-align-t{
  @apply  pt-[140px] 2xl:pt-[200px]
}
.h1{
  @apply font-din text-[30px]  2xl:text-[80px] text-main-500 leading-none uppercase;
}
.h1-white{
  @apply pt-8 lg:pt-0 font-din text-[40px]  2xl:text-[80px] text-main-500 lg:text-white leading-none uppercase;
 
}
.h3{
  @apply text-3xl ;
  font-weight: 500;
}
h4{
  @apply lg:text-[25px] text-xl;
  font-weight: bold;
}
.image-container{
  @apply relative lg:flex items-center justify-end w-full mt-[80px] ;

}
.image-container img {

}
.image-container.left{
  @apply justify-start;
}
.box-rounded-1{
  border-radius: 0 117px 0 0;
}
.line-logo{
  width: 100%;
  position: relative;

}
.line-logo:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #2b292b;
  top: 60%;
  left: 0;
  transform: translate(0%, -50%);


}
.box-logo{
  @apply  w-[150px] lg:w-[200px];
  position: relative;
  z-index: 999;
  display: block;
  margin:0 auto;
  background-color: #fff;
}
.box-logo img{
  @apply w-[90px] lg:w-[114px];

  display: block;
  margin:0 auto
}
.box-logo-footer{
  height: 60px;
  display: flex;
  align-items: center;
}
.font-s-italic{
  font-family: 'Avenir Next W1G Light';
  font-style: italic;


}
.line-oblique{
  width:3px;
  background-color: #c229b7;
  pointer-events: none;
  transform: rotate(5deg);;

}
.text-leading-1{
  @apply text-[202px] font-bold text-main-600 uppercase leading-none relative top-0  2xl:top-[40px];
  font-family: 'DinCondensedDemiBold';

  width: 700px;
  margin: 0 auto;

}
.text-leading-1:before{
  @apply top-[1] left-[-130px] lg:top-[41px] 2xl:left-[-190px] w-[150px] h-[150px] 2xl:w-[236px] 2xl:h-[234px] absolute;
  content: "";
  background: url('../public/images/left-icon.png') no-repeat center top;
  background-size: contain

}
.text-leading-2{
  @apply text-[302px] font-bold text-main-600 uppercase leading-none relative;
  font-family: 'DinCondensedDemiBold';
  width: 510px;
  left:-120px;
  margin: 0 auto;

}
.text-leading-2:before{
  @apply  w-[150px] h-[170px] 2xl:w-[256px] 2xl:h-[274px] left-[310px] 2xl:left-[400px] top-[122px] 2xl:top-[12px] absolute;
  content: "";
    background: url('../public/images/right-icon.png') no-repeat center top;
    background-size: contain

}
.tableinfo{
  width: 100%;
  border: #c229b7 2px solid;
}

.tableinfo td{
  border: #c229b7 1px solid;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.tableinfo tr td:first-child{
  color: #6408c4;
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
  width:350px;
}
.tableinfo tr td:nth-child(2) p{
  color: #6408c4;
  font-family: 'Avenir Next W1G Ultra';

    font-style: italic;
}
.list-question {
  @apply max-w-[1090px] mx-auto;
}
.list-question li{
  display: block;
  text-align: left;
  margin-bottom: 20px;
}
.list-question li  > p{
  font-size: 25px;
  color: #b806ac;
  font-weight: 600;
  font-style: italic

}
.list-question li div{
  @apply flex space-x-3;
}
.list-question li div label{
  @apply flex items-center space-x-3 text-[20px];
}

.VideoTag{
@apply object-cover w-screen h-screen;
}
